import React from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Layout, { Header, SignupDialog } from 'ery-components/src/lib/EryLandingLayout';

import auth0 from '../utils/auth0';
import withRoot from '../withRoot';

const SignupPage = () => {
  return (
    <Layout auth0={auth0} header={<Header loginButton={false} signupButton={false} />}>
      <SignupDialog maximize />
    </Layout>
  );
};

export default withRoot(SignupPage);
